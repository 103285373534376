<template>
  <div>
    <v-list-item
      v-for="(i, index) in documentTabs"
      :key="index"
      link
      @click="newTab(i)"
    >
      <v-icon class="mr-4">{{i.icon}}</v-icon>
      <v-list-item-content>
        <span>{{i.typeName}}</span>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import recentsController from '@/recentsController'
import tabController from '@/tabController'

export default {
  methods: {
    newTab (tab) {
      recentsController.addTab(tabController.create(tab))
    }
  },
  props: {
    documentTabs: {
      type: Array,
      required: true
    }
  }
}
</script>
